import { differenceInMilliseconds, startOfDay } from "date-fns";
import { ITimingObject, ITimingStateVector, TimingObject } from "timing-object";
import {
    createSetTimingsrc,
    createUpdateGradually,
    createUpdateStepwise,
    setTimingsrcWithCustomUpdateFunction,
} from "timingsrc";
import {
    TDefaultSetTimingsrcFactory,
    TSetTimingsrcFunction,
} from "timingsrc/build/es2019/types";
import device from "current-device";
import { createWindow } from "timingsrc/build/es2019/factories/window";

const DEFAULT_THRESHOLD = 1;
const DEFAULT_TIME_CONSTANT = 0.5;
const DEFAULT_TOLERANCE = 0.025;
// seems to not work on safari desktop
const DEFAULT_TOLERANCE_OSX = device.ios() ? DEFAULT_TOLERANCE : 0.5;

console.log(
    "is safari",
    window !== null &&
        window.navigator.userAgent.includes("Safari") &&
        !window.navigator.userAgent.includes("Chrome")
);

const createDefaultSetTimingsrc: TDefaultSetTimingsrcFactory = (
    createSetTimingsrc,
    createUpdateGradually,
    createUpdateStepwise,
    setTimingsrcWithCustomUpdateFunction,
    window
) =>
    window !== null &&
    window.navigator.userAgent.includes("Safari") &&
    !window.navigator.userAgent.includes("Chrome")
        ? (...args: Parameters<TSetTimingsrcFunction>) =>
              createSetTimingsrc(
                  setTimingsrcWithCustomUpdateFunction,
                  createUpdateStepwise(DEFAULT_TOLERANCE_OSX)
              )(...args)
        : createSetTimingsrc(
              setTimingsrcWithCustomUpdateFunction,
              createUpdateGradually(
                  DEFAULT_TIME_CONSTANT,
                  DEFAULT_THRESHOLD,
                  DEFAULT_TOLERANCE
              )
          );

const setTimingSrc = createDefaultSetTimingsrc(
    createSetTimingsrc,
    createUpdateGradually,
    createUpdateStepwise,
    setTimingsrcWithCustomUpdateFunction,
    createWindow()
);

const mainVideoElement = document.querySelector<HTMLVideoElement>(
    ".desktop_device video"
);
const secondVideoElement = document.querySelector<HTMLVideoElement>(
    ".landscape_device video"
);
const thirdVideoElement = document.querySelector<HTMLVideoElement>(
    ".portrait_device video"
);
const videos = [mainVideoElement, secondVideoElement, thirdVideoElement];
const debugTextElement = document.querySelector(".debug-text");

const VIDEO_DURATION = 713.6;

const getProjectedPlaybackPosition = (duration) => {
    const secondsElapsed = getElapsedDayTimeInSeconds();
    return secondsElapsed % duration;
};

const getElapsedDayTimeInSeconds = () => {
    const now = new Date();
    const start = startOfDay(now);
    return differenceInMilliseconds(now, start) / 1000;
};

const createTimingObject = async () =>
    new Promise<ITimingObject>((resolve) => {
        const timingObject = new TimingObject({ velocity: 1 });
        timingObject.addEventListener("readystatechange", () => {
            if (timingObject.readyState === "open") {
                window.requestAnimationFrame(() => {
                    const projectedPlaybackPosition =
                        getProjectedPlaybackPosition(VIDEO_DURATION);
                    timingObject.update({
                        position: projectedPlaybackPosition,
                        velocity: 1
                    });
                    resolve(timingObject);
                });
            }
        });
    });

const syncVideo = (video: HTMLVideoElement, timingObject: ITimingObject) => {
    const convertLinearTimeToLoop = ({
        timestamp,
        acceleration,
        position,
        velocity,
    }: ITimingStateVector): ITimingStateVector => {
        return {
            timestamp,
            acceleration,
            velocity,
            position: position % VIDEO_DURATION,
        };
    };

    return setTimingSrc(video, timingObject, convertLinearTimeToLoop);
};

const fmt = (_seconds: number) =>
    `"${Math.floor(_seconds / 60)
        .toString()
        .padStart(2, "0")}:${Math.floor(_seconds % 60)
        .toString()
        .padStart(2, "0")}"`;

const updateStats = (timingObject) => {
    const { position } = timingObject.query();
    const videoPosition = position % VIDEO_DURATION;
    // debugTextElement.innerHTML = `pos: ${videoPosition.toFixed(2)}<br />`;
    document.body.style.setProperty(
        "--video-playtime-current",
        fmt(videoPosition)
    );
    requestAnimationFrame(() => updateStats(timingObject));
};

const createVideoStats = (timingObject) => {
    document.body.style.setProperty(
        "--video-playtime-total",
        fmt(VIDEO_DURATION)
    );
    requestAnimationFrame(() => updateStats(timingObject));
};

async function run() {
    const timingObject = await createTimingObject();
    createVideoStats(timingObject);

    videos.forEach(async (video) => {
        const target = video.getAttribute("device-target");
        video.addEventListener("video-start", () => {
            console.log("sync", target);
            const destroyFn = syncVideo(video, timingObject);
            const handleDestroy = () => {
                console.log("destroy", target);
                video.removeEventListener("video-stop", handleDestroy);
                destroyFn();
            };
            video.addEventListener("video-stop", handleDestroy);
        });
    });
}

run();

// toggleAudioElement.addEventListener("click", handleToggleAudio);
